import "./index.scss";

import React from "react";

import Arrow from "../Arrow";

const Button = ({ children, variant, withArrow, ...props }) => {
  // variant: transparent | filled | secondary

  return (
    <button
      className={`button ${variant ?? ""} ${withArrow ? "withArrow" : ""}`}
      {...props}
    >
      <div className="button__content">
        <span>{children}</span>
        {withArrow ? <Arrow /> : <></>}
      </div>
      <span className="button__bg" />
    </button>
  );
};

export default Button;
