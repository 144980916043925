import "./index.scss";

import React from "react";

import {
  SUPPORT_QTS_EMAIL,
  SUPPORT_QTS_PHONE,
  TWITTER_URL,
} from "../../../constants/config";

import LogoImg from "../../../assets/logo.svg";
import TwitterImg from "../../../assets/twitter.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__main">
        <a href="/">
          <img className="footer__logo" src={LogoImg} alt="logo" />
        </a>
        <div className="footer__main-box">
          <p className="footer__main-desc">
            QuantaSphere Tech is a leading technology firm at the intersection
            of technologies
            <br /> and innovation, dedicated to crafting bespoke solutions for
            the modern world.
          </p>
          <p className="footer__main-desc">
            At Quanta Sphere, we combine our experience in developing complex
            products with
            <br /> state-of-the-art technology to create unique solutions to
            your business challenges
          </p>
        </div>
        <div className="footer__contacts">
          <p className="footer__contacts-item">{SUPPORT_QTS_EMAIL}</p>
          <p className="footer__contacts-item phone">{SUPPORT_QTS_PHONE}</p>
          <p className="footer__contacts-item">
            Quanta Tech Systems LLC
            <br /> 285 Fulton Street, Suite 8500
            <br /> New York, NY, 10007
          </p>
        </div>
      </div>
      <div className="footer__bottom">
        <p>
          QuantaSphere Tech 2024 <br />© All rights reserved
        </p>
        <div className="footer__contacts-item social">
          <img
            src={TwitterImg}
            onClick={() => window.open(TWITTER_URL)}
            alt="twitter"
          />
        </div>
        <a href="/privacy-policy">
          <p>Privacy policy</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
