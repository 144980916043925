import "./index.scss";

import React from "react";

import { MARQUEE_TOPICS } from "../../../constants/general";

import CubeImg from "../../../assets/cube.png";

const Marquee = () => {
  const Item = () => (
    <div className="marquee__item">
      {MARQUEE_TOPICS.map((topic) => (
        <>
          <p>{topic}</p>
          <img src={CubeImg} alt="cube" />
        </>
      ))}
    </div>
  );

  return (
    <div className="marquee">
      <Item />
      <Item />
      <Item />
      <Item />
    </div>
  );
};

export default Marquee;
