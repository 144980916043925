import React from "react";

import Header from "../../components/ui/Header";
import PreviewSection from "../../components/HomePage/PreviewSection";
import ServicesSection from "../../components/HomePage/ServicesSection";
import CooperationSection from "../../components/HomePage/CooperationSection";
import Marquee from "../../components/ui/Marquee";
import Footer from "../../components/ui/Footer";

const HomePage = () => (
  <div>
    <Header />
    <main>
      <PreviewSection />
      <ServicesSection />
      <CooperationSection />
      <Marquee />
    </main>
    <Footer />
  </div>
);

export default HomePage;
