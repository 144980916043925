import "./index.scss";

import React from "react";

import Button from "../../ui/Button";

import ScrollDownImg from "../../../assets/scroll-down.svg";

import { MAIN_TOPICS } from "../../../constants/general";
import { BOOK_CONSULTATION_URL } from "../../../constants/config";

const PreviewSection = () => {
  return (
    <div className="preview">
      <img
        src={ScrollDownImg}
        className="preview__scroll-down"
        alt="scroll for more"
      />
      <div className="preview__content">
        <h1>
          <span>Quanta Tech Systems</span>
          <br />
          Developer of AI-Driven Cybersecurity SaaS
          <br /> Solutions Utilizing Blockchain Technology
        </h1>
        <Button
          variant="filled"
          withArrow
          onClick={() => window.open(BOOK_CONSULTATION_URL)}
        >
          BOOK CONSULTATION NOW
        </Button>
      </div>
      <nav>
        {MAIN_TOPICS.map((topic, i) => (
          <Button
            variant="secondary"
            key={i}
            onClick={() => window.open(BOOK_CONSULTATION_URL)}
          >
            {topic}
          </Button>
        ))}
      </nav>
    </div>
  );
};

export default PreviewSection;
