// buttons on Preview
const MAIN_TOPICS = [
  "Financial Services",
  "Healthcare",
  "Government and Public Sector",
  "Compliance and Regulatory Tech",
  "Education",
  "Real Estate",
  "Energy and Utilities",
  "Manufacturing",
  "Retail and E-commerce",
  "Transportation and Logistics",
  "Insurance",
  "Media and Entertainment",
];

// used for Marquee before footer
const MARQUEE_TOPICS = [
  "Cybersecurity Services",
  "Cloud Computing Services",
  "Data Analytics and Business Intelligence",
  "IT Support and Maintenance",
  "Compliance and Regulatory Tech",
  "Software Development",
  "Digital Marketing Services",
  "Tech Consulting and Strategy Services",
];

export { MARQUEE_TOPICS, MAIN_TOPICS };
