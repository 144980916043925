import React from "react";

import Header from "../../components/ui/Header";
import Footer from "../../components/ui/Footer";

const PrivacyPolicyPage = () => (
  <div>
    <Header />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
    <br />
    <br />
    <br />
    <br />
    <br />
    <Footer />
  </div>
);

export default PrivacyPolicyPage;
