import "./index.scss";

import React from "react";

const Arrow = () => (
  <div className="arrow">
    <span className="arrow__point" />
    <span className="arrow__point" />
    <span className="arrow__point" />
    <span className="arrow__point" />
    <span className="arrow__point" />
    <span className="arrow__point" />
    <span className="arrow__line" />
  </div>
);

export default Arrow;
