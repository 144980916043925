import "./index.scss";

import React from "react";

import Button from "../../ui/Button";

import { BOOK_CONSULTATION_URL } from "../../../constants/config";

const CooperationSection = () => {
  return (
    <div className="cooperation">
      <div className="cooperation__card">
        <div className="cooperation__content">
          <p>WORK WITH US</p>
          <h3>Let's build something great together</h3>
        </div>
        <Button
          variant="filled"
          withArrow
          onClick={() => window.open(BOOK_CONSULTATION_URL)}
        >
          BOOK CONSULTATION NOW
        </Button>
      </div>
    </div>
  );
};

export default CooperationSection;
