import "./index.scss";
import React from "react";

import {
  SUPPORT_QTS_EMAIL,
  SUPPORT_QTS_PHONE,
} from "../../../constants/config";

import LogoImg from "../../../assets/logo.svg";
import PhoneImg from "../../../assets/phone.svg";
import MailImg from "../../../assets/mail.svg";

const Header = () => {
  return (
    <header className="header">
      <a href="/">
        <img src={LogoImg} className="header__logo" alt="logo" />
      </a>
      <p className="header__desc">
        At Quanta Sphere, we combine our experience in developing complex
        products with
        <br /> state-of-the-art technology to create unique solutions to your
        business challenges
      </p>
      <div className="header__contacts">
        <p className="header__contacts-lbl">Contact us:</p>
        <div className="header__contacts-box">
          <p className="header__contacts-item">
            <img src={PhoneImg} alt="phone" />
            <span>{SUPPORT_QTS_PHONE}</span>
          </p>
          <p className="header__contacts-item">
            <img src={MailImg} alt="mail" />
            <span>{SUPPORT_QTS_EMAIL}</span>
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
