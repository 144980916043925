import "./index.scss";

import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

import { motion, useInView } from "framer-motion";

import { getRem } from "../../../utils";

import Button from "../../ui/Button";

import Vpn4uImg from "../../../assets/services/vpn4u.png";
import LayerGuard4uImg from "../../../assets/services/layer-guard4u.png";
import FrameBgImg from "../../../assets/services/bg.svg";

import { LAYER_GUARD4U_URL, VPN4U_URL } from "../../../constants/config";

const PROJECTS = [
  {
    id: 1,
    name: "VPN4U - leading VPN solution",
    imgSrc: Vpn4uImg,
    desc: "VPN4U is a leading VPN solution offering robust security and privacy with high-speed connections and global server coverage. Built on military-grade AES-256 encryption, VPN4U ensures your data remains secure, whether you're on a public Wi-Fi network or browsing at home. It supports multiple protocols like OpenVPN and WireGuard for flexibility and performance. With a strict no-logs policy, DNS leak protection, ads protection and kill switch functionality, VPN4U is engineered to safeguard your sensitive data, making it a trusted choice for secure and anonymous internet access.",
    link: VPN4U_URL,
  },
  {
    id: 2,
    name: "LayerGuard4U - Leading business VPN solution",
    imgSrc: LayerGuard4uImg,
    desc: "LayerGuard4U is a powerful VPN solution for businesses requiring secure, encrypted network access. It offers end-to-end encryption to protect sensitive data and enables remote employees to connect securely from any location. The service includes real-time threat protection, seamless integration with identity management systems, and supports network segmentation to control access. It’s scalable for growing businesses, with detailed analytics and reporting tools to monitor network activity. LayerGuard4U is designed for reliability, with 24/7 customer support to ensure your network remains secure at all times.",
    link: LAYER_GUARD4U_URL,
  },
  {
    id: 3,
    name: "QuantaProtect MDR Solution",
    imgSrc: Vpn4uImg,
    desc: "QuantaProtect MDR is a managed detection and response solution that protects your business from cyber threats. It continuously monitors your network for suspicious activities, offering real-time threat detection and quick incident response. Utilizing advanced threat intelligence and behavioral analysis, it neutralizes threats before they cause damage. Managed by cybersecurity experts, it integrates with your existing security setup for easy management. The service is scalable for businesses of all sizes, providing detailed incident reports and actionable insights to help mitigate risks, ensuring your network remains secure.",
    link: "",
  },
];

const ServicesMItem = ({ project }) => {
  const itemRef = useRef(null);
  const isInView = useInView(itemRef, { once: true, amount: 1 });

  useEffect(() => {
    if (isInView) {
      const cardEl = itemRef.current.querySelector(".services-m__card");
      const cardInnerEl = cardEl.querySelector(".services-m__card-inner");
      const buttonEl = itemRef.current.querySelector(".services-m__item-btn");

      const addedHeight = cardInnerEl.offsetHeight;

      cardEl.style.paddingBottom = `${addedHeight + 30}px`;

      itemRef.current.classList.add("opened");

      setTimeout(() => {
        cardInnerEl.style.opacity = 1;

        setTimeout(() => {
          if (buttonEl) {
            buttonEl.style.opacity = 1;
          }
        }, 300);
      }, 900);
    }
  }, [isInView]);

  return (
    <div className="services-m__item" ref={itemRef}>
      <h4>
        <span>{project.name}</span>
      </h4>
      <div className="services-m__card">
        <div className="services-m__card-inner">
          <img
            src={project.imgSrc}
            alt="vpn4u.io"
            style={!project.link ? { filter: "grayscale(1)" } : {}}
          />
          <p>{project.desc}</p>
        </div>
        <div className="services-m__border">
          <span></span>
          <span></span>
        </div>
        <div className="services-m__border right">
          <span></span>
          <span></span>
        </div>
        <div className="services-m__card-bg" />
      </div>
      {project.link && (
        <div className="services-m__item-btn">
          <Button
            variant="transparent"
            onClick={() => window.open(project.link)}
          >
            DISCOVER MORE
          </Button>
        </div>
      )}
    </div>
  );
};

const ServicesSection = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1025px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const imgWrapperVariants = {
    initial: isDesktop
      ? {
          backgroundPositionX: `${getRem(140)}, calc(100% - ${getRem(140)})`,
        }
      : {
          backgroundPositionX: `${getRem(50)}, calc(100% - ${getRem(50)})`,
        },
    animate: {
      backgroundPositionX: `${getRem(0)}, calc(100% - ${getRem(0)}`,
      transition: {
        delay: 0.5,
        duration: 0.35,
      },
    },
  };

  const imgVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.7,
        delay: 1.5,
      },
    },
  };

  const imgBgVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 0.1,
      },
    },
  };

  const contentVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 1.5,
        duration: 0.7,
      },
    },
  };

  const cardVariants = {
    initial: {
      transform: "translate(-99.7%, 0)",
      clipPath: "polygon(98% 0, 100% 0%, 100% 100%, 98% 100%)",
    },
    animate: {
      transform: "translate(0, 0)",
      clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0% 100%)",
      transition: {
        duration: 0.5,
        delay: 2,
      },
    },
  };

  const cardVariantsAlt = {
    initial: {
      transform: "translate(99.7%, 0)",
      clipPath: "polygon(0% 0, 2% 0%, 2% 100%, 0% 100%)",
    },
    animate: {
      transform: "translate(0, 0)",
      clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0% 100%)",
      transition: {
        duration: 0.5,
        delay: 2,
      },
    },
  };

  const cardTextVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.7,
        delay: 2.5,
      },
    },
  };

  const buttonVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 3,
        duration: 0.7,
      },
    },
  };

  const h4Variants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 2,
        duration: 0.7,
      },
    },
  };

  return !isMobile ? (
    <section className="services">
      <h1>
        <span>Our Products</span> and Innovations Research:
      </h1>
      <div className="services__box">
        {PROJECTS.map((project, i) => (
          <motion.div
            className="services__item"
            initial="initial"
            whileInView="animate"
            viewport={{ amount: 1, once: true }}
          >
            <motion.div
              className="services__item-img"
              variants={imgWrapperVariants}
            >
              <div className="services__item-img-inner">
                <motion.img
                  src={project.imgSrc}
                  alt="vpn4u.io"
                  variants={imgVariants}
                  style={i === 2 ? { filter: "grayscale(1)" } : {}}
                />
              </div>
              <motion.img
                className="services__item-img-bg"
                src={FrameBgImg}
                alt="bg"
                variants={imgBgVariants}
              />
            </motion.div>
            <motion.div
              className="services__item-content"
              variants={contentVariants}
            >
              <motion.h4 variants={h4Variants}>
                <span>{project.name}</span>
              </motion.h4>
              <motion.div
                className="services__item-card"
                variants={i % 2 && isDesktop ? cardVariantsAlt : cardVariants}
              >
                <motion.p variants={cardTextVariants}>{project.desc}</motion.p>
              </motion.div>
              {project.link && (
                <motion.div
                  className="services__item-btn"
                  variants={buttonVariants}
                >
                  <Button
                    variant="transparent"
                    onClick={() => window.open(project.link)}
                  >
                    DISCOVER MORE
                  </Button>
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        ))}
      </div>
    </section>
  ) : (
    <section className="services-m">
      <h1>
        <span>Our Products</span> and Innovations Research:
      </h1>
      <div className="services-m__box">
        {PROJECTS.map((project, i) => (
          <ServicesMItem project={project} key={i} />
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
